import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DLT } from '../redux/actions/action'; // Import the delete action
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { BASE_URL } from '../Config';
import Swal from "sweetalert2";
const Checkout = () => {
    const cartItems = useSelector((state) => state.cartreducer.carts);
    const [quantities, setQuantities] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [shippingCost, setShippingCost] = useState(50); // Example fixed shipping cost
    const [taxRate, setTaxRate] = useState(0.1); // Example tax rate (10%)
    const [finalPrice, setFinalPrice] = useState(0);
    const dispatch = useDispatch(); // Dispatch function
    //form submit
    
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        town_city: "",
        state: "",
        zip: "",
        create_account: "",
        bill_ship_same:"",
        ship_address: "",
        ship_city: "",
        ship_state: "",
        ship_zip: "",
        order_note: "",
        items: [],
        total_amount:"",
        payment_mode:"",
        loading: false,
      });

      const [submittedData, setSubmittedData] = useState(null);

    
      const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
      
        // Handle bill_ship_same checkbox logic
        if (name === "bill_ship_same") {
          setFormData((prevState) => {
            if (checked) {
              // Copy billing fields to shipping fields
              return {
                ...prevState,
                bill_ship_same: checked,
                ship_address: prevState.address,
                ship_city: prevState.town_city,
                ship_state: prevState.state,
                ship_zip: prevState.zip,
              };
            } else {
              // Clear shipping fields when unchecked
              return {
                ...prevState,
                bill_ship_same: checked,
                ship_address: "",
                ship_city: "",
                ship_state: "",
                ship_zip: "",
              };
            }
          });
        } else {
          // Handle other form fields
          setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
          }));
        }
      };
      
      const handleSubmit = async (event) => {
        event.preventDefault();

        const items = cartItems.map((item) => ({
            item_id: item.id,
            quantity: quantities[item.id],
            price: item.price,
          }));
          const fullFormData = {
            ...formData,
            items,
            total_amount: finalPrice.toFixed(2), // Include total_amount
        };
        setSubmittedData(fullFormData);
        console.log("Form submitted:", fullFormData);
          setFormData((prevState) => ({ ...prevState, loading: true }));

          try {
            const response = await axios.post(`${BASE_URL}/api/order_store`, fullFormData);
            Swal.fire({
                icon: "success",
                title: "Order",
                text: "Order Placed successfully",
            });
            setFormData({
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                address: "",
                town_city: "",
                state: "",
                zip: "",
                create_account: false,
                bill_ship_same: false,
                ship_address: "",
                ship_city: "",
                ship_state: "",
                ship_zip: "",
                order_note: "",
                loading: false,
              });
              setQuantities({}); // Reset quantities
              dispatch({ type: "CLEAR_CART" });
            console.log("Form submitted successfully:", response.data.info);
          } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error submitting form",
            });
            console.error("Error submitting form:", error);
          } finally {
            setFormData((prevState) => ({ ...prevState, loading: false }));
          }
        
    
        // Simulate form submission delay
       
      };

    useEffect(() => {
        // Initialize quantities and total price based on cart items
        
        const initialQuantities = {};
        let initialTotalPrice = 0;
        cartItems.forEach(item => {
            initialQuantities[item.id] = item.qnty; // Set initial quantity
            initialTotalPrice += item.qnty * item.price; // Calculate initial total price
        });
        setQuantities(initialQuantities);
        setTotalPrice(initialTotalPrice);
    }, [cartItems]);

    useEffect(() => {
        // Recalculate the final price whenever total price, shipping, or tax changes
        const taxAmount = totalPrice * taxRate; // Calculate tax based on total price
        //const finalTotalPrice = totalPrice + taxAmount + shippingCost;
        const finalTotalPrice = totalPrice ;
        setFinalPrice(finalTotalPrice);
    }, [totalPrice, shippingCost, taxRate]);

    const handleQuantityChange = (id, newQuantity) => {
        const updatedQuantities = { ...quantities, [id]: newQuantity };
        setQuantities(updatedQuantities);

        // Recalculate total price
        let newTotalPrice = 0;
        cartItems.forEach(item => {
            const itemQuantity = updatedQuantities[item.id] || item.qnty;
            newTotalPrice += itemQuantity * item.price;
        });
        setTotalPrice(newTotalPrice);
    };

    const handleRemove = (id) => {
        dispatch(DLT(id)); // Dispatch remove action
    };

    return (
        <>
            <Header />
            <form onSubmit={handleSubmit} >
            <section className="checkout-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 upper-column">
                            <div className="upper-box">
                                <div className="customer single-box">Returning Customer? <a href="/existing-customer">Click here to Login</a></div>
                                <div className="coupon single-box">Have a Coupon? <a href="#">Click here to enter your code</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 left-column">
                        <div className="inner-box billing-form">
                                <div className="billing-info">
                                    <h4 className="sub-title">Billing Details</h4>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>First Name*</label>
                                            <div className="field-input">
                                                <input
                                                 type="text" 
                                                 name="first_name"
                                                 value={formData.first_name}
                                                 onChange={handleChange}
                                                 />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Last Name*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="last_name"
                                                value={formData.last_name}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Email Address*</label>
                                            <div className="field-input">
                                                <input 
                                                type="email" 
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Phone Number*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Address*</label>
                                            <div className="field-input">
                                            <textarea
                                                
                                                name="address" 
                                                className="address form-control"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Town/City*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="town_city"
                                                value={formData.town_city}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                                            <label>State*</label>
                                            <div className="select-column select-box">
                                                <select className="nice-select selectmenu" name="state" id="ui-id-2" value={formData.state}
                                                onChange={handleChange}>
                                                    <option selected="selected">Select Option</option>
                                                    <option>United State</option>
                                                    <option>Australia</option>
                                                    <option>Canada</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Zip Code*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="zip"
                                                value={formData.zip}
                                                onChange={handleChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6 col-md-12 col-sm-12">
                                            <div className="create-acc">
                                                <div className="custom-controls-stacked">
                                                    <label className="custom-control material-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="create_account"
                                                        className="material-control-input"
                                                        value={formData.zip}
                                                        onChange={handleChange} 
                                                    />
                                                        <span className="material-control-indicator"></span>
                                                        <span className="description">Create an Account?</span>
                                                    </label>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                               
                                </div>
                               
                            </div>
                            <div className="inner-box">
                                <div className="billing-info">
                                    
                              
                                    <div className="row">
                                    
                                    <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                                    <p className="ship-add">Shipping Address </p>   
                                    <p className="ship-check-box">
                                    <div className="create-acc">
                                              <div className="custom-controls-stacked">
                                                    <label  className="custom-control material-checkbox ">
                                                    <input
                                                        type="checkbox"
                                                        name="bill_ship_same"
                                                        value={formData.bill_ship_same}
                                                        checked={formData.bill_ship_same}
                                                        onChange={handleChange} 
                                                        className="material-control-input"
                                                    />
                                                        <span className="material-control-indicator"></span>
                                                        <span className="description"> Same as billing address </span>
                                                    </label>
                                                </div> 
                                            </div>
                                            </p>
                                        </div>
                                       
                                       
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Shipping Address*</label>
                                            <div className="field-input">
                                            <textarea
                                                name="ship_address" 
                                                className="address form-control"
                                                value={formData.ship_address}
                                                onChange={handleChange}
                                            />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Town/City*</label>
                                            <div className="field-input form-control">
                                            <input 
                                                type="text" 
                                                name="ship_city"
                                                value={formData.ship_city}
                                                onChange={handleChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>State*</label>
                                            <div className="select-column select-box">
                                            <select className="nice-select selectmenu" name="ship_state" id="ui-id-2" style={{width: '100%'}} value={formData.ship_state}
                                                onChange={handleChange}>
                                                    <option selected="selected">Select Option</option>
                                                    <option>United State</option>
                                                    <option>Australia</option>
                                                    <option>Canada</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Zip Code*</label>
                                            <div className="field-input form-control">
                                            <input 
                                                type="text" 
                                                name="ship_zip"
                                                value={formData.ship_zip}
                                                onChange={handleChange} 
                                                />
                                            </div>
                                        </div>
                                       
                                    </div>
                                
                                </div>
                                <div className="additional-info">
                                    <div className="note-book">
                                        <label>Order Notes</label>
                                        <textarea
                                            name="order_note" 
                                            className="address form-control"
                                            value={formData.order_note}
                                            onChange={handleChange}
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 right-column">
                            <div className="inner-box">
                                <div className="order-info">
                                    <h4 className="sub-title">Your Order</h4>
                                    {cartItems.length === 0 ? (
                                        <p>Your cart is empty. Please add items to your cart.</p>
                                    ) : (
                                        <div className="order-product">
                                            <ul className="order-list clearfix">
                                                <li className="title clearfix">
                                                    <p>Product</p>
                                                    <span>Total</span>
                                                </li>
                                                {cartItems.map((item, index) => (
                                                    <li key={item.id} className="single-box clearfix">
                                                         <input
                                                            type="hidden"
                                                            value={item.id}
                                                            name={`items[${index}][item_id]`}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            value={quantities[item.id]}
                                                            name={`items[${index}][quantity]`}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            value={item.price}
                                                            name={`items[${index}][price]`}
                                                        />
                                                        
                                                        <div className="single-box clearfix">
                                                            <img src={`${BASE_URL}/public/uploads/products/${item.images[0]}`} alt=""/>
                                                            <h6>{item.product_name}</h6>
                                                            <span>
                                                                Rs. {item.price} x
                                                                <input
                                                                    type="number"
                                                                    value={quantities[item.id]}
                                                                    min="1"
                                                                    onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                                                    style={{ width: '50px', marginLeft: '10px' }}
                                                                />
                                                                <button
                                                                    onClick={() => handleRemove(item.id)}
                                                                    style={{ marginLeft: '15px', color: 'red', cursor: 'pointer' }}
                                                                >
                                                                    X
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </li>
                                                ))}
                                                <li className="order-total clearfix">
                                                    <h6>Order Total</h6>
                                                    <span>Rs. {finalPrice.toFixed(2)}</span>
                                                    <input
                                                        type="text"
                                                        value={finalPrice.toFixed(2)}
                                                        name="total_amount"
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>

                                <div className="payment-info">
                                <h4 className="sub-title">Payment Process</h4>
                                    <div className="payment-inner">
                                        <div className="option-block">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control material-checkbox">
                                                    <input type="radio" name="payment_mode" className="material-control-input" value="COD" onChange={handleChange}/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash on Delivery.
                                                    <span className="material-control-indicator"></span>
                                                    <span className="description"></span>
                                                </label>
                                            </div>
                                            
                                        </div>
                                        <div className="option-block">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control material-checkbox">
                                                    <input type="radio" name="payment_mode" className="material-control-input" value="Online" onChange={handleChange}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Online Payment.
                                                    <span className="material-control-indicator"></span>
                                                    
                                                </label>
                                            </div>
                                        </div>
                                        <div className="btn-box">
                                        <button type="submit" className="theme-btn-two">Place Your Order <i className="flaticon-right-1"></i></button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </form>
            <Footer />
        </>
    );
};

export default Checkout;
