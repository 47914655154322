const INIT_STATE = {
    carts: JSON.parse(localStorage.getItem('carts')) || [] // Load from local storage initially
  };
  
  // Helper function to save carts to local storage
  const saveToLocalStorage = (carts) => {
    localStorage.setItem('carts', JSON.stringify(carts));
  };
  
  export const cartreducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case "ADD_CART":
        const itemIndex = state.carts.findIndex((item) => item.id === action.payload.id);
        let updatedCarts;
  
        if (itemIndex >= 0) {
          // If product exists, update the quantity by replacing with the new quantity
          updatedCarts = state.carts.map((item) => 
            item.id === action.payload.id
              ? { ...item, qnty: action.payload.qnty }  // Directly replace quantity
              : item
          );
        } else {
          // Add new item to the cart with the specified quantity
          updatedCarts = [...state.carts, { ...action.payload, qnty: action.payload.qnty }];
        }
  
        saveToLocalStorage(updatedCarts);  // Save updated cart to local storage
        return {
          ...state,
          carts: updatedCarts
        };
  
      case "RMV_CART":
        // Filter out the item to remove from the cart
        const updatedCartAfterRemoval = state.carts.filter((item) => item.id !== action.payload);
        saveToLocalStorage(updatedCartAfterRemoval);  // Save updated cart to local storage
        return {
          ...state,
          carts: updatedCartAfterRemoval
        };
        case "CLEAR_CART": // Add the CLEAR_CART case here
        return {
          ...state,
          carts: [], // Reset the cart to an empty array
        };
  
     
  
      default:
        return state;
    }
  };
  