import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/esm/Table';
import { useDispatch, useSelector } from 'react-redux';
import { DLT } from '../redux/actions/action';
import axios from 'axios';
import { BASE_URL } from '../Config';

function Navbar() {
    const [categories, setCategories] = useState([]);
    const [price, setPrice] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cartreducer.carts);
    const open = Boolean(anchorEl);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/category_menu`);
            const categoryData = response.data.list;
            const structuredCategories = buildCategoryHierarchy(categoryData);
            setCategories(structuredCategories);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const buildCategoryHierarchy = (categoryData) => {
        const categoryMap = {};
        categoryData.forEach(cat => {
            categoryMap[cat.id] = { ...cat, subcategories: [] };
        });

        const structuredCategories = [];
        categoryData.forEach(cat => {
            if (cat.parent_id) {
                categoryMap[cat.parent_id]?.subcategories.push(categoryMap[cat.id]);
            } else {
                structuredCategories.push(categoryMap[cat.id]);
            }
        });
        return structuredCategories;
    };

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const deleteItem = (id) => dispatch(DLT(id));

    useEffect(() => {
        const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.qnty, 0);
        setPrice(totalPrice);
    }, [cartItems]);

    return (
        <>
            <header className="main-header style-two">
                <div className="header-top">
                    <div className="auto-container">
                        <div className="top-inner clearfix">
                            <div className="top-left pull-left">
                                <ul className="info clearfix">
                                    <li><i className="flaticon-email"></i><a href="mailto:support@example.com">info@ezzybrezzyu.com</a></li>
                                    <li><i className="flaticon-phone-ringing"></i> +880171889547</li>
                                </ul>
                            </div>
                            <div className="top-right pull-right">
                                <ul className="social-links clearfix">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-upper">
                    <div className="auto-container">
                        <div className="upper-inner">
                            <figure className="logo-box" style={{margin:'0 0 0'}}><a href="#"><img src="../assets_web/images/logo.png" alt="" style={{width:'100px'}}/></a></figure>
                            <ul className="menu-right-content clearfix">
                                
                                <li><a href="#"><i className="flaticon-user"></i></a></li>
                                <li className="shop-cart">
                                    <Badge badgeContent={cartItems.length} color="success"
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <i className="flaticon-shopping-cart-1"></i>
                                    </Badge>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {cartItems.length ? (
                                            <div className="card_details" style={{ width: "24rem", padding: 10 }}>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Photo</th>
                                                            <th>Restaurant Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cartItems.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <NavLink to={`/productdetails/${item.id}`} onClick={handleClose}>
                                                                      
                                                                    <img 
                                                                        src={item.images && item.images.length > 0 
                                                                                ? `${BASE_URL}/public/uploads/products/${item.images[0]}` 
                                                                                : "path/to/default/image.jpg"} 
                                                                        alt={item.rname || "Product image"} 
                                                                        style={{ width: "5rem", height: "5rem" }} 
                                                                        />
                                                                    </NavLink>
                                                                </td>
                                                                <td>
                                                                    <span>{item.rname}</span>
                                                                    <span>Price : ₹{item.price}</span><br />
                                                                    <span>Quantity : {item.qnty}</span>
                                                                </td>
                                                                <td onClick={() => deleteItem(item.id)} style={{ color: "red", fontSize: 20, cursor: "pointer" }}>
                                                                    <i className="fas fa-trash largetrash"></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <p className="text-center">Total : ₹{price}</p>
                                                        <Button variant="primary" as={Link} to="/checkout" className="col-lg-12">
                                                            Checkout
                                                        </Button>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        ) : (
                                            <div className="card_details d-flex justify-content-center align-items-center" style={{ width: "24rem", padding: 10, position: "relative" }}>
                                                <i className="fas fa-close smallclose" onClick={handleClose} style={{ position: "absolute", top: 2, right: 20, fontSize: 23, cursor: "pointer" }}></i>
                                                <p style={{ fontSize: 22 }}>Your cart is empty</p>
                                                <img src="./cart.gif" alt="" className="emptycart_img" style={{ width: "5rem", padding: 10 }} />
                                            </div>
                                        )}
                                    </Menu>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-lower">
                    <div className="auto-container">
                        <div className="outer-box clearfix">
                            <div className="category-box pull-left">
                                <p>All Categories</p>
                                <ul className="category-content">
                                    {categories.map(category => (
                                        <li key={category.id} className="dropdown-option">
                                            <i className="flaticon-category-icon"></i>
                                            <NavLink to={`/`}>{category.name}</NavLink>
                                            {category.subcategories.length > 0 && (
                                                <ul>
                                                    {category.subcategories.map(subcategory => (
                                                        <li key={subcategory.id}>
                                                            <NavLink to={`/category/${subcategory.id}`}>{subcategory.name}</NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="menu-area pull-left">
                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About</Link></li>
                                            <li><a href="#">Blog</a></li>
                                            <li><Link to="/contact-us">Contact</Link></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navbar;
