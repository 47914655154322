import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from '../Config';

const CategoryEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    //alert(id);
    
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        category_name: '',
        image: null,
        hidden_image: '',
        top_menu: false
    });
    const [categoryParentList, setCategoryParentList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');

                // Fetch category parent list
                const parentListResponse = await axios.get(`${BASE_URL}/api/category_parent_list`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCategoryParentList(parentListResponse.data.parent_list);

                // Fetch current category data for edit
                const categoryResponse = await axios.get(`${BASE_URL}/api/category_edit/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { name, gallery, top_menu } = categoryResponse.data.category_list;
                setFormData({
                    category_name: name,
                    hidden_image: gallery,
                    top_menu: top_menu === 'yes'
                });
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, [id]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleChange = (event) => {
        const { name, type, checked, value, files } = event.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0], // handle single file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const token = localStorage.getItem('token');
        const formDataToSend = new FormData();
        formDataToSend.append('category_name', formData.category_name);
        formDataToSend.append('top_menu', formData.top_menu ? 'yes' : 'no');
        if (formData.image) {
            formDataToSend.append('image', formData.image); // New image
        } else {
            formDataToSend.append('hidden_image', formData.hidden_image); // Existing image
        }
    
        try {
            const response = await axios.put(`${BASE_URL}/api/category_update/${id}`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: `${response.data.name} Category Updated`,
                    text: "Successfully!",
                });
                setSuccessMessage(response.data.message);
            } else if (response.data.statusCode === 203) {
                Swal.fire({
                    icon: "error",
                    title: `${response.data.name} category already exists`,
                    text: "Failed!",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: response.data.status,
                    text: response.data.statusCode === 422 ? `${response.data.errors.category_name}, ${response.data.errors.image}` : "Failed!",
                });
            }
        } catch (error) {
            console.error("Error updating category", error);
        }
    };
    

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Edit Category</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="form-group">
                                                    <input
                                                        type='text'
                                                        id="category_name"
                                                        name='category_name'
                                                        className='form-control input-lg mb-3'
                                                        placeholder='Category Name'
                                                        value={formData.category_name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type='file'
                                                        id="image"
                                                        name='image'
                                                        className='form-control input-lg mb-3'
                                                        onChange={handleChange}
                                                    />
                                                    {formData.hidden_image && (
                                                        <div>
                                                            <img
                                                                src={`${BASE_URL}/public/uploads/category/${formData.hidden_image}`}
                                                                alt="Current Category Image"
                                                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type='checkbox'
                                                        id="top_menu"
                                                        name='top_menu'
                                                        className='form-check-input'
                                                        checked={formData.top_menu}
                                                        onChange={handleChange}
                                                    /> Top Menu
                                                </div>
                                                <button type='submit' className='btn btn-primary left-content-end'>Update</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
};

export default CategoryEdit;
