import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BASE_URL } from '../Config';

const Order = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const [data, setData] = useState({ columns: [], rows: [] });
    const [loading, setLoading] = useState(true);
    const [abstractData, setAbstractData] = useState({ columns: [], rows: [] });
    const [showModal, setShowModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderLoading, setOrderLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${BASE_URL}/api/order_list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                const orders = response.data.orders;

                const rows = orders.map((order, index) => ({
                    SrNo: index + 1,
                    'Customer Name': order.customer_name,
                    'Shipping Address': order.shipping_address,
                    'Total': order.total,
                    'Payment': (
                        order.status === 0 ? (
                            <span className="btn btn-light text-success">Pending</span>
                        ) : order.status === 1 ? (
                            <span>Received</span>
                        ) : (
                            <span>Untracked</span>
                        )
                    ),
                    Action: (
                        <div>
                            <button 
                                className="btn btn-light text-success"
                                onClick={() => handleView(order.id)}
                            >
                                <i className="fa fa-eye"></i>
                            </button>
                            <Link className="btn btn-light text-info" to={`/edit/${order.id}`}><i className="fa fa-edit"></i></Link>  
                            <button className="btn btn-light text-danger"><i className="fa fa-trash"></i></button>
                        </div>
                    )
                }));

                const columns = [
                    { label: 'SrNo', field: 'SrNo', sort: 'asc', width: 150 },
                    { label: 'Customer Name', field: 'Customer Name', sort: 'asc', width: 270 },
                    { label: 'Shipping Address', field: 'Shipping Address', sort: 'asc', width: 270 },
                    { label: 'Total', field: 'Total', sort: 'asc', width: 270 },
                    { label: 'Payment', field: 'Payment', sort: 'asc', width: 270 },
                    { label: 'Action', field: 'Action', sort: 'asc', width: 100 }
                ];

                setAbstractData({ columns, rows });
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
                setLoading(false);
            });
    }, []);

    const handleView = (orderId) => {
        //alert(orderId);
        setOrderLoading(true);
        setShowModal(true);

        const token = localStorage.getItem('token');
        axios.get(`${BASE_URL}/api/view_order/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setSelectedOrder(response.data.order);
                //console.log(response.data.order);
                setOrderLoading(false);
            })
            .catch(error => {
                console.error('Error fetching order details:', error);
                setOrderLoading(false);
            });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedOrder(null);
    };

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Order List</h6>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            {loading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Spinner animation="grow" size="sm" variant="primary" />
                                                    <Spinner animation="grow" size="sm" variant="primary" />
                                                    <Spinner animation="grow" size="sm" variant="primary" />
                                                </div>
                                            ) : (
                                                abstractData.rows.length > 0 ? (
                                                    <MDBDataTable
                                                        striped
                                                        bordered
                                                        hover
                                                        noBottomColumns={true}
                                                        data={abstractData}
                                                    />
                                                ) : (
                                                    <p>No data available</p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {orderLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="grow" size="sm" variant="primary" />
                            <Spinner animation="grow" size="sm" variant="primary" />
                            <Spinner animation="grow" size="sm" variant="primary" />
                        </div>
                    ) : selectedOrder ? (
                        <div>
                            <p className='text-black'><strong>Customer Name:</strong> {selectedOrder.customer_name}</p>
                            <p className='text-black'><strong>Shipping Address:</strong> {selectedOrder.shipping_address}</p>
                            <p className='text-black'><strong>Total:</strong> {selectedOrder.total}</p>
                            <p className='text-black'><strong>Status:</strong> {selectedOrder.status === 0 ? "Pending" : selectedOrder.status === 1 ? "Received" : "Untracked"}</p>
                            {/* Add more details as needed */}
                        </div>
                    ) : (
                        <p>No order details available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
};

export default Order;
