import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { Link } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import Spinner from 'react-bootstrap/Spinner';
import { BASE_URL } from '../Config';

const CategorySubList = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [abstractData, setAbstractData] = useState({ columns: [], rows: [] });

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const refetchCategories = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/api/sub_category_list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const categories = response.data.category_list;

            const rows = categories.map((category, index) => ({
                SN: index + 1,
                'Sub Category Name': category.CategoryName,
                'Category Name': category.ParentName,
                File: (
                    <img
                        src={`${BASE_URL}/uploads/category/${category.gallery}`}
                        alt={category.CategoryName}
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                    />
                ),
                Action: (
                    <div>
                        <Link className="btn btn-light text-primary" to={`/sub-category_edit/${category.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                        <button
                            className="btn btn-light text-danger"
                            onClick={() => handleDelete(category.id)}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                ),
            }));

            const columns = [
                { label: 'Sr.No.', field: 'SN', sort: 'asc', width: 150 },
                { label: 'Sub Category Name', field: 'Sub Category Name', sort: 'asc', width: 270 },
                { label: 'Category Name', field: 'Category Name', sort: 'asc', width: 270 },
                { label: 'File', field: 'File', sort: 'asc', width: 270 },
                { label: 'Action', field: 'Action', sort: 'asc', width: 100 },
            ];

            setAbstractData({ columns, rows });
            setLoading(false);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${BASE_URL}/api/sub_category_delete/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.data.statusCode === 200) {
                        Swal.fire(
                            'Deleted!',
                            response.data.message || 'Sub-category has been deleted.',
                            'success'
                        );
                        // Refresh the list
                        refetchCategories();
                    } else {
                        throw new Error(response.data.message || 'Unexpected error occurred.');
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        error.response?.data?.message || 'Something went wrong.',
                        'error'
                    );
                }
            }
        });
    };

    useEffect(() => {
        refetchCategories();
    }, []);

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Sub Categories List</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <Link className='btn btn-primary' to="/category-sub">
                                                        <i className="fa fa-plus mr-2"></i>Add
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            {loading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Spinner animation="grow" size="sm" variant="primary" />
                                                    <Spinner animation="grow" size="sm" variant="primary" />
                                                    <Spinner animation="grow" size="sm" variant="primary" />
                                                </div>
                                            ) : (
                                                abstractData.rows.length > 0 ? (
                                                    <MDBDataTable
                                                        striped
                                                        bordered
                                                        hover
                                                        noBottomColumns={true}
                                                        data={abstractData}
                                                    />
                                                ) : (
                                                    <p>No data available</p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
};

export default CategorySubList;
