import React, { useState } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../Config';

const Category = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        category_name: '',
        image: null,
        top_menu: false,
    });

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleChange = (event) => {
        const { name, value, type, checked, files } = event.target;
        if (type === 'checkbox') {
            setFormData((prev) => ({
                ...prev,
                [name]: checked,
            }));
        } else if (type === 'file') {
            setFormData((prev) => ({
                ...prev,
                [name]: files[0], // handle single file
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const formDataToSend = new FormData();
            formDataToSend.append('category_name', formData?.category_name || ''); // Safety check
            formDataToSend.append('image', formData?.image || null); // Safety check
            formDataToSend.append('top_menu', formData?.top_menu ? 'yes' : 'no');

            const response = await axios.post(`${BASE_URL}/api/category_store`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    title: `${response.data.name} Category Added`,
                    text: 'Successfully!',
                });
                setSuccessMessage(response.data.message);
                setFormData({
                    category_name: '',
                    image: null,
                    top_menu: false,
                });
                document.getElementById('image').value = '';
            } else if (response.data.statusCode === 422) {
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.errors
                        ? `${response.data.errors.category_name || ''}, ${response.data.errors.image || ''}`
                        : 'Failed!',
                });
            }
        } catch (error) {
            console.error('Error during category submission:', error);
            Swal.fire({
                icon: 'error',
                title: 'Submission Error',
                text: 'An error occurred while submitting the category.',
            });
        }
    };

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Add Category</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="form-group">
                                                    <input
                                                        type='text'
                                                        id="category_name"
                                                        name='category_name'
                                                        className='form-control input-lg mb-3'
                                                        placeholder='Category Name'
                                                        value={formData.category_name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type='file'
                                                        id="image"
                                                        name='image'
                                                        className='form-control input-lg mb-3'
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type='checkbox'
                                                        id="top_menu"
                                                        name='top_menu'
                                                        className='form-check-input'
                                                        checked={formData.top_menu}
                                                        onChange={handleChange}
                                                    /> Top Menu
                                                </div>
                                                <button type='submit' className='btn btn-primary left-content-end'>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
};

export default Category;
