import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DLT, ADD, REMOVE } from '../redux/actions/action';
import Header from './Header';
import Footer from './Footer';
import Slider from "react-slick";  // Importing react-slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';  // Importing axios for API calls
import { BASE_URL } from '../Config';

const CardsDetails = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const getdata = useSelector((state) => state.cartreducer.carts);
  
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const compare = () => {
    let comparedata = getdata.filter((e) => e.id == id);
    setData(comparedata);
  };

  const send = (e) => {
    // Check if the product already exists in the cart
    const existingProduct = getdata.find((item) => item.id === e.id);
  
    if (existingProduct) {
      // If product exists, update the quantity (no need to add, just replace with the new value)
      const updatedProduct = {
        ...existingProduct,
        qnty: quantity  // Set the quantity to the current one
      };
      dispatch(ADD(updatedProduct));  // Dispatch the updated product
    } else {
      // If product doesn't exist, add it to the cart with the new quantity
      const newProduct = { ...e, qnty: quantity };  // Ensure you set the correct quantity here
      dispatch(ADD(newProduct));  // Add the new product
    }
  };

  const buyNow = () => {
    dispatch(ADD({ ...data[0], quantity }));
    history('/checkout');
  };

  const dlt = (id) => {
    dispatch(DLT(id));
    history("/");
  };

  const remove = (item) => {
    dispatch(REMOVE(item));
  };

  const incrementQuantity = () => {
    setQuantity(prev => prev + 1);
  };

  const decrementQuantity = () => {
    setQuantity(prev => (prev > 1 ? prev - 1 : 1));
  };

  // Fetch data from the API
  useEffect(() => {
    axios.get(`${BASE_URL}/api/product_by_category/${id}`)
      .then((response) => {
        console.log(response.data.product_list.product_list);  // Check the product list in the console
        setData(response.data.product_list.product_list); 
       // alert(data.product_name);    // Update the state with the product list
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  const thumbBoxStyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  // Define settings only after data is set
  const settings = data?.images?.length > 0 ? {
    dots: true,
    infinite: data?.images?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: data?.images?.length > 1,
    beforeChange: (current, next) => setActiveSlide(next),
  } : {};

  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index);
    setActiveSlide(index);
  };

  return (
    <>
      <Header />
      <section className="page-title centred">
        <div className="pattern-layer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/background/page-title.jpg)` }}></div>
        <div className="auto-container">
          <div className="content-box">
            <h1>Product Details</h1>
            <ul className="bread-crumb clearfix">
              <li><i className="flaticon-home-1"></i><a href="/">Home</a></li>
              <li>Product Details</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="product-details product-details-4">
        <div className="auto-container">
          <div className="product-details-content">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                {data?.images?.length > 0 && (
                  <>
                    {/* Main Slider */}
                    <Slider {...settings} ref={sliderRef}>
                      {data?.images?.length > 0 ? (
                        data.images.map((image, index) => (
                          <div key={index} className="slider-content">
                            <div className="product-image">
                              <figure className="image">
                                <img
                                  src={`${BASE_URL}/public/uploads/products/${image}`}
                                  alt={`Product ${index + 1}`}
                                  style={{ width: "400px" }}
                                />
                              </figure>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No images available</div>
                      )}
                    </Slider>

                    {/* Thumbnail Navigation */}
                    <div className="slider-content">
                      <div className="slider-pager centred">
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: "0",
                            margin: "0",
                            overflow: "hidden",
                            display: "flex",
                          }}
                        >
                          {data?.images?.map((image, index) => (
                            <li
                              key={index}
                              onClick={() => handleThumbnailClick(index)}
                              className={activeSlide === index ? "active" : ""}
                            >
                              <figure>
                                <img
                                  src={`${BASE_URL}/public/uploads/products/${image}`}
                                  alt={`Thumbnail ${index + 1}`}
                                  style={{ width: "100px" }}
                                />
                              </figure>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="product-info">
                  <h3>{data.product_name || ''}</h3>
                  <h5>{data.category_name || ''}</h5>
                  <div className="text" style={{ color: "black" }}>
                    {data.description || 'No description available.'}
                  </div>
                  <div className="othre-options clearfix">
                    <div className="item-box">
                      <div className="single-item clearfix">
                        <div className="item-quantity">
                          <button className="quantity-btn" style={{ background: '#00000000' }} onClick={decrementQuantity}>-</button>
                          <input className="quantity-spinner" type="text" value={quantity} readOnly />
                          <button className="quantity-btn" style={{ background: '#00000000' }} onClick={incrementQuantity}>+</button>
                        </div>
                        <h5>{data.product_name}<span>Rs. {data.price || '0.00'}</span></h5>
                      </div>
                    </div>
                    <div className="btn-box">
                      <button type="button" className="theme-btn-two" onClick={() => send(data)}>Add to cart</button>
                      <button type="button" className="theme-btn-two" onClick={buyNow}>Buy Now</button>
                    </div>
                  </div>
                  <div className="other-links" style={{ paddingLeft: '0px' }}>
                    <ul className="clearfix">
                      <li><strong>SKU:</strong> {data.sku || 'N/A'}</li>
                      <li><strong>Category:</strong> <a href="/">{data.category_name || 'N/A'}</a></li>
                      <li><strong>Tags:</strong> <a href="/">glasses</a>, <a href="/">t-shirts</a>, <a href="/">watches</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CardsDetails;
